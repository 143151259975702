import axios from 'axios';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import '../login.css';

const LoginPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');


    useEffect(() => {
        sessionStorage.removeItem("PID");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleLogin = async (e) => {
        e.preventDefault();

        const url = `/api/member/login.json`;
        const headers = { "Content-Type": "application/json" };
        const body = { email: username, pw: password }
        const { data } = await axios.post(url, body, { headers });
        // console.log(data);

        if (data.status === 200) {
            dispatch({ type: 'login', token: data.token, course:data.course });
            navigate(`/chat`);
        }
        else {
            alert('아이디 또는 암호가 틀립니다.');
        }
    };


    return (
        <div>
            <form onSubmit={handleLogin} method='post'>
                <div className="login-container" style={{ display: 'flex', justifyContent: 'center', marginTop: '80px' }}>
                    <Card title="로그인" style={{ width: '400px' }}>
                        <div className="p-field">
                            <label htmlFor="username">아이디</label>
                            <InputText id="username" value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="아이디를 입력하세요."
                                style={{ width: '100%' }}
                                autoFocus 
                            />
                        </div>

                        <div className="p-field">
                            <label htmlFor="password">암호</label>
                            <Password id="password" value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="암호를 입력하세요."
                                feedback={false}
                                style={{ width: '100%' }}
                            />
                        </div>

                        <div style={{ marginTop: '26px' }}>
                            <Button label="로그인" onClick={handleLogin} style={{ width: '100%' }} />
                        </div>
                    </Card>
                </div>
            </form>

            <footer style={{ width: '100%', padding: '10px', textAlign: 'center', marginTop: '20px' }}>
                <p>2016 &copy; ihongss.com</p>
            </footer>
        </div>
    );
};

export default LoginPage;