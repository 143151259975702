import axios from 'axios';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {ButtonGroup} from 'primereact/buttongroup';


const { ace } = window;

const WebboardPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { token } = useSelector((state) => state.AuthReducer);

    const [id, setId] = useState('');
    const [editor, setEditor] = useState(null);


    useEffect(() => {
        handleInit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleInit = async () => {
        const url = `/api/member/file_qa.json`;
        const headers = { "Content-Type": "application/json", "auth": token };
        const { data } = await axios.get(url, { headers });
        // console.log(data);

        if (data.status === 200) {
            var editor1 = ace.edit("editor1", {
                theme: "ace/theme/eclipse",
                mode: "ace/mode/java",
                maxLines: Infinity,
                wrap: true,
                /* fontFamily : 'Nanum Gothic', */
                fontSize: "16px",
                autoScrollEditorIntoView: true,
                showPrintMargin: false,
                keyboardHandler: "ace/keyboard/vscode",
            });

            editor1.setValue(data.doc.filedata);
            editor1.gotoLine(0, 0);

            setId(data.doc._id);
            setEditor(editor1);
        }
    };


    const handleLogout = () => {
        if (window.confirm('로그아웃 할까요??')) {
            dispatch({ type: 'logout' });
            navigate(`/`);
        }
    };


    const handleSave = async () => {
        const url = `/api/member/file_data_update.json`;
        const headers = { "Content-Type": "application/json", "auth": token };
        const body = { id: id, data: editor.getValue() };

        const { data } = await axios.put(url, body, { headers });
        // console.log(data);
        if (data.status === 200) {
            alert('저장되었습니다.');
        }
        else if (data.status < 0) {
            dispatch({ type: 'logout' });
            navigate(`/`);
        }
    };


    const handleListPage = () => {
        navigate(`/list`);
    };

    const handleChatPage = () => {
        navigate(`/chat`);
    };

    const handleNoticePage = () => {
        navigate(`/notice`);
    };

    const handleBoardPage = () => {
        navigate(`/board`);
    };


    return (
        <div>
            <div className="card flex justify-content-center">
                <ButtonGroup>
                    <Button onClick={handleChatPage}>실시간메시지</Button>&nbsp;
                    <Button onClick={handleListPage}>강의자료</Button>&nbsp;
                    <Button onClick={handleBoardPage} severity="secondary">게시판</Button>&nbsp;
                    <Button onClick={handleNoticePage}>공지사항</Button>&nbsp;
                    <Button onClick={handleLogout}>로그아웃</Button>
                </ButtonGroup>
            </div>

            <hr />

            <Button onClick={handleSave} size="small"  style={{ width: 'auto' }} severity="info">저장하기</Button>&nbsp;
            <p style={{color: "red"}}>*** 내용을 입력하고 저장하기 버튼을 눌러 주세요.</p>
            

            <div className="container">
                <div className="row">
                    <div id="editor1"></div>
                </div>
            </div>

            <footer>
                <div className="container">
                    <div className="row">
                        <p className="copyright text-muted">2016 &copy; ihongss.com</p>
                    </div>
                </div>
            </footer>

        </div>
    );
};

export default WebboardPage;