import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Link, useNavigate } from 'react-router-dom';

import {ButtonGroup} from 'primereact/buttongroup';

const { ace } = window;

const ListPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { token } = useSelector((state) => state.AuthReducer);

    const [docs, setDocs] = useState([]);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        handleData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleData = async () => {
        var pid = sessionStorage.getItem("PID");

        const url = `/api/member/course_file_list.json`;
        const headers = { "Content-Type": "application/json", "auth": token };
        const { data } = await axios.get(url, { headers });
        //console.log(data);

        if (data.status === 200) {
            setDocs(data.result);
            if (pid === null) {
                pid = data.result[0].tree_node_id;
            }
            sessionStorage.setItem("PID", pid);
            handleContent(pid);
        }
        else if (data.status < 0) {
            dispatch({ type: 'logout' });
            navigate(`/`);
        }
    };



    const nl2br = (str, is_xhtml) => {
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    };


    const handleContent = async (pid) => {
        setVisible(false);
        sessionStorage.setItem("PID", pid);

        const url = `/api/member/ebook_file_data_list.json?tree_node_id=${pid}`;
        const headers = { "Content-Type": "application/json", "auth": token };
        const { data } = await axios.get(url, { headers });
        // console.log(data);

        if (data.status === 200) {
            var cnt = -1;
            var editor = [];

            const contentData = document.getElementById('content_data');
            contentData.innerHTML = "";

            // for (let i = data.docs.length - 1; i >= 0; i--) {
            for (let i = 0; i < data.docs.length; i++) {
                const mode = data.docs[i].editor_mode;

                if (mode.startsWith('ace')) {
                    if (mode.endsWith('text')) {
                        const textDiv = document.createElement('div');
                        const paragraph = document.createElement('p');
                        paragraph.innerHTML = nl2br(data.docs[i].editor_data);
                        textDiv.appendChild(paragraph);
                        contentData.appendChild(textDiv);
                    }
                    else {
                        cnt++;

                        const editorContainer = document.createElement('div');
                        editorContainer.style.display = "inline-block";
                        editorContainer.style.width = "100%";

                        const editorDiv = document.createElement('div');
                        editorDiv.id = `editor_${cnt}`;
                        editorDiv.style.marginTop = "0px";
                        editorContainer.appendChild(editorDiv);

                        const htmlHr = document.createElement('hr');
                        editorContainer.appendChild(htmlHr);

                        contentData.appendChild(editorContainer);

                        editor[cnt] = ace.edit(`editor_${cnt}`, {
                            theme: "ace/theme/eclipse",
                            mode: data.docs[i].editor_mode,
                            maxLines: Infinity,
                            wrap: true,
                            fontSize: "17px",
                            autoScrollEditorIntoView: true
                        });

                        editor[cnt].setValue(data.docs[i].editor_data);
                        editor[cnt].gotoLine(0, 0);
                        editor[cnt].setReadOnly(true);
                        editor[cnt].setHighlightActiveLine(false);
                        editor[cnt].setHighlightGutterLine(false);
                        editor[cnt].renderer.$cursorLayer.element.style.display = "none";
                    }
                } else if (mode === 'tag_file') {
                    const htmlDiv = document.createElement('div');
                    htmlDiv.style.display = "inline-block";
                    htmlDiv.style.width = "100%";

                    const img = document.createElement('img');
                    img.classList.add('img-fluid');
                    img.style.border = "1px solid #cccccc";
                    img.id = `image_view_${cnt}`;
                    if (data.docs[i].editor_data !== "") {
                        img.src = data.docs[i].editor_data;
                    } else {
                        img.src = `${process.env.PUBLIC_URL}/default.jpeg`;
                    }
                    htmlDiv.appendChild(img);

                    const htmlHr = document.createElement('hr');
                    htmlDiv.appendChild(htmlHr);

                    contentData.appendChild(htmlDiv);
                }
            }
        }

        else if (data.status < 0) {
            dispatch({ type: 'logout' });
            navigate(`/`);
        }
    };

   
    const handleChatPage = () => {
        navigate(`/chat`);
    };

    const handleLogout = () => {
        if (window.confirm('로그아웃 할까요??')) {
            dispatch({ type: 'logout' });
            navigate(`/`);
        }
    };

    const handleBoardPage = () => {
        navigate(`/board`);
    };


    const handleNoticePage = () => {
        navigate(`/notice`);
    };

    const handleListPage = () => {
        navigate(`/list`);
    };


    

    return (
        <div>
            <div className="card flex justify-content-center">
                <Sidebar visible={visible} position='right' onHide={() => setVisible(false)}>
                    {
                        docs.map(item => (
                            <p key={item._id} style={{ margin: '3px' }}>
                                <Link style={{ textDecoration: 'none' }} onClick={() => { handleContent(item.tree_node_id) }}>
                                    - {item.title}
                                </Link>
                            </p>
                        ))
                    }
                </Sidebar>
                <ButtonGroup>
                    <Button onClick={handleChatPage}>실시간메시지</Button>&nbsp;
                    <Button onClick={handleListPage} severity="secondary">강의자료</Button>&nbsp;
                    <Button onClick={handleBoardPage}>게시판</Button>&nbsp;
                    <Button onClick={handleNoticePage}>공지사항</Button>&nbsp;
                    <Button onClick={handleLogout}>로그아웃</Button>
                </ButtonGroup>
            </div>
            <hr />
            
            <Button onClick={() => setVisible(true)} size="small" style={{ width: 'auto' }} severity="info">목록표시</Button>

            { /*<p style={{ color: "red" }}>*** 자료의 정렬 순서가 아래쪽에서 위쪽으로 표시됩니다.</p> */ }

            <div className="container">
                <div className="row">
                    <div id="content_data">
                        <p>잠시만 기다려 주세요.</p>
                    </div>
                </div>
            </div>

            <footer>
                <div className="container">
                    <div className="row">
                        <p className="copyright text-muted">2016 &copy; ihongss.com</p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default ListPage;
